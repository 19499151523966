.home {

    &__scrolldown {
        cursor: pointer;
        position: absolute;
        bottom: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 50%;
        transform: translateX(-50%);
        gap: 10px;

        p {
            font: normal normal normal 14px/21px Satoshi;
        }
    }

    &__section {
        // outline: 1px solid var(--blue);
        min-height: 100vh;
        position: relative;

        &__wrapper {
            padding-left: 80px;
            padding-right: 80px;
            padding-top: 300px;
        }

        &__flex {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        // === Stage Home ===
        &__stagehome {
            display: flex;

            height: 100vh;
            flex-direction: column;

            @media (min-width: 751px) {
                justify-content: center;
            }

            @media (max-width: 750px) {
                text-align: center;
            }
            
            padding: 0px 40px;

            &__wrapper {
                max-width: 1440px;
                margin: 0 auto;
                width: 100%;
                margin-top: 15%;

                @media (max-width: 750px) {
                    padding-top: 20vh;
                }
            }

            &__subheadline {
                text-transform: uppercase;
                margin-bottom: 30px;
            }

            &__headline {
                text-transform: uppercase;
                display: flex;
                flex-direction: column;

                &__subtitle {
                    
                    @media (min-width: 751px) {
                        text-align: right;
                    }
                }
            }
        }

        // === Stage Text ===
        &__stagetext {
            display: flex;
            height: 100vh;
            flex-direction: column;

            @media (min-width: 751px) {
                justify-content: center;
            }

            @media (max-width: 750px) {
                text-align: center;
            }

            padding: 0px 40px;

            &__right {
                @media (min-width: 751px) {
                    align-items: flex-end;
                    text-align: right;
                }
            }

            &__wrapper {
                width: 100%;
                
                @media (min-width: 751px) {
                    width: 50%;
                }

                @media (max-width: 750px) {
                    padding-top: 20vh;
                }
            }

            &__subheadline {
                text-transform: uppercase;
                margin-bottom: 30px;
            }

            &__headline {
                text-transform: uppercase;

                &__title {
                    line-height: 1;
                }
            }
        }

        // === Stage Center ===
        &__stagecenter {
            display: flex;
            height: 100vh;
            justify-content: center;
            align-items: start;
            
            text-align: center;
            
            @media (min-width: 751px) {
                align-items: center;
            }
            
            &__wrapper {
                margin: 0px 250px;
                
                @media (max-width: 750px) {
                    padding-top: 20vh;
                }

                @media (max-width: 921px) {
                    margin: 0px 20px;
                }
            }

            &__icon {
                display: block;
                width: 60px;
                height: 60px;
                margin: 0 auto;
                margin-bottom: 40px;
            }

            &__title {
                text-transform: uppercase;
                margin-bottom: 25px;
            }

            &__text {
                font-size: 14px;
                margin-bottom: 20px;

                @media (max-width: 921px) {
                    font-size: 12px;
                }
            }

            &__subtext {
                font-size: 14px;
                color: var(--grey);
            }

        }

        // === Stage Buttons ===
        &__stagebuttons {
            display: flex;
            height: 100vh;
            justify-content: center;
            align-items: start;
            
            text-align: center;
            
            @media (min-width: 751px) {
                align-items: center;
            }

            &__wrapper {
                margin: 0px 300px;

                @media (max-width: 750px) {
                    padding-top: 20vh;
                }
                
                @media (max-width: 921px) {
                    margin: 0px 20px;
                }
            }

            &__title {
                text-transform: uppercase;
                margin-bottom: 25px;
            }

            &__btns {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 15px;

                @media (max-width: 921px) {
                    flex-direction: column;
                }
            }
        }
    }

    &__button {
        display: block;
        margin: 0 auto;
        width: fit-content;
    }

}