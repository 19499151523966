.header {
    position: fixed;
    width: 100%;
    z-index: 1;

    &__background {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: -1;

        &__blur {
            // -webkit-backdrop-filter: blur(20px);
            // backdrop-filter: blur(20px);
            // background: linear-gradient(1turn, rgba(170, 170, 170, 0.1), rgba(170, 170, 170, 0.1));
        }
    }

    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 30px 40px;

        transition: all ease 0.3s;
    }

    &__logo {
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        &__img {
            width: 42px;
            height: auto;

            transition: all ease 0.3s;

            transform-origin: center;

            &__big {
                transform: scale(1.5);
            }

        }

        &__text {
            font-size: 20px;
            text-transform: uppercase;
            letter-spacing: 0.9px;
            opacity: 1;

            transition: all ease 0.3s;

            &__hide {
                opacity: 0;
            }

        }

    }

    &__menu {
        &__list {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 50px;
            list-style: none;
            gap: 60px;
            margin: 0;
            white-space: nowrap;

            @media (max-width: 750px) {
                display: none;
            }

            @media (max-width: 921px) {
                gap: 30px;
            }

            &__item {
                cursor: pointer;
                padding-bottom: 5px;
                border-bottom: 2px solid transparent;
                position: relative;

                p {
                    color: var(--white);
                }

                span {
                    color: var(--grey);
                    margin-right: 5px;
                    font-size: 0.8em;
                }

                &:hover {
                    border-color: var(--grey);
                }

                &__active {
                    border-color: var(--grey);
                }

                &__underline {
                    position: absolute;
                    bottom: -2px;
                    left: 0;
                    width: 0;
                    height: 2px;
                    background-color: var(--white);
                    display: none;

                    &__active {
                        display: block;
                    }
                }
            }
        }
    }

    &__nav {
        &__list {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 30px;
            list-style: none;
            margin: 0;

            &__item {

                &__btn {
                    @media (max-width: 921px) {
                        display: none;
                    }
                }

                &__menulogo {
                    cursor: pointer;
                    height: 40px;
                    width: 40px;
                    border: 1px solid var(--white);
                    border-radius: 11px;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    gap: 8px;
                    transition: all ease-in-out 0.2s;

                    &__line {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        gap: 8px;
                    }

                    &__line__item {
                        height: 3px;
                        width: 3px;
                        background-color: var(--white);
                    }

                    &:hover {
                        background-color: var(--white);
                        color: var(--black);
                    }

                    &:hover .header__nav__list__item__menulogo__line__item {
                        background-color: var(--black);
                    }
                }
            }
        }
    }

    &__sidebar {
        position: fixed;
        width: 100%;
        height: 100vh;
        top: 0;
        right: 0;
        background-color: transparent;
        color: var(--white);

        backdrop-filter: blur(41px);

        transform: translateX(100%);

        box-shadow: 0px 0px 0px 0px var(--blue);

        transition: all ease 0.3s;

        &__open {
            box-shadow: 0px 0px 1px 1px var(--blue);
            transform: translateX(0px);
        }

        &__wrapper {
            position: relative;
            padding: 0 40px;
            height: 80%;
        }

        &__close {
            position: absolute;
            top: 0px;
            right: 0px;
            cursor: pointer;
            height: 40px;
            width: 40px;
            border: 1px solid var(--white);
            border-radius: 11px;
            transition: all ease-in-out 0.2s;
            margin: 30px 50px;
            
            @media (max-width: 921px) {
                margin: 20px 20px;
            }
        }

        &__content {
            margin-top: 100px;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            
            @media (max-width: 921px) {
                margin-top: 50px;
            }

            &__logo {
                display: flex;
                align-items: center;
                justify-content: center;

                &__img {
                    width: 50px;
                    height: 50px;
                }

                &__text {
                    font-size: 31px;
                    text-transform: uppercase;
                    letter-spacing: 1.41px;
                }
            }

            &__text {
                max-width: 556px;
                margin: 0 auto;
                font: normal normal normal 20px/30px Satoshi;
                
                @media (max-width: 921px) {
                    font: normal normal normal 16px/22px Satoshi;
                }
            }

            &__credits {

                &__title {
                    font: normal normal normal 14px/32px Satoshi;
                    margin-bottom: 30px;
                    
                    @media (max-width: 921px) {
                        margin-bottom: 10px;
                    }
                }

                &__members {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 4px;

                    @media (max-width: 921px) {
                        gap: 0px;
                    }

                    &__item {
                        width: fit-content;
                        font: normal normal normal 16px/29px Satoshi;
                        text-decoration: none;
                        position: relative;

                        &::after,
                        &::before {
                            position: absolute;
                            width: 100%;
                            height: 1px;
                            background: currentColor;
                            top: 100%;
                            left: 0;
                            pointer-events: none;
                        }

                        &::before {
                            content: '';
                            transform-origin: 100% 50%;
                            transform: scale3d(0, 1, 1);
                            transition: transform 0.3s;
                        }



                        &:hover::before {
                            transform-origin: 0% 50%;
                            transform: scale3d(1, 1, 1);
                        }
                    }
                }
            }

            &__social {
                display: flex;
                gap: 16px;
                align-items: center;
                justify-content: center;

                &__logo {
                    width: 30px;
                    height: 30px;
                    
                    @media (max-width: 921px) {
                        width: 25px;
                        height: 25px;
                    }
                }
            }

            &__allrights {
                margin: 0 auto;
            }

        }
    }
}