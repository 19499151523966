// === Satoshi ===

@font-face {
    font-family: 'Satoshi';
    src: url('./fonts/Satoshi/Satoshi-Black.otf') format('opentype');
    font-weight: 900;
}

@font-face {
    font-family: 'Satoshi';
    src: url('./fonts/Satoshi/Satoshi-BlackItalic.otf') format('opentype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Satoshi';
    src: url('./fonts/Satoshi/Satoshi-Bold.otf') format('opentype');
    font-weight: 700;
}

@font-face {
    font-family: 'Satoshi';
    src: url('./fonts/Satoshi/Satoshi-BoldItalic.otf') format('opentype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Satoshi';
    src: url('./fonts/Satoshi/Satoshi-Italic.otf') format('opentype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Satoshi';
    src: url('./fonts/Satoshi/Satoshi-Light.otf') format('opentype');
    font-weight: 300;
}

@font-face {
    font-family: 'Satoshi';
    src: url('./fonts/Satoshi/Satoshi-LightItalic.otf') format('opentype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Satoshi';
    src: url('./fonts/Satoshi/Satoshi-Medium.otf') format('opentype');
    font-weight: 500;
}

@font-face {
    font-family: 'Satoshi';
    src: url('./fonts/Satoshi/Satoshi-MediumItalic.otf') format('opentype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Satoshi';
    src: url('./fonts/Satoshi/Satoshi-Regular.otf') format('opentype');
    font-weight: 400;
}

// === ClashDisplay ===
@font-face {
    font-family: 'ClashDisplay';
    src: url('./fonts/ClashDisplay/ClashDisplay-Bold.otf') format('opentype');
    font-weight: 700;
}

@font-face {
    font-family: 'ClashDisplay';
    src: url('./fonts/ClashDisplay/ClashDisplay-Extralight.otf') format('opentype');
    font-weight: 200;
}

@font-face {
    font-family: 'ClashDisplay';
    src: url('./fonts/ClashDisplay/ClashDisplay-Light.otf') format('opentype');
    font-weight: 300;
}

@font-face {
    font-family: 'ClashDisplay';
    src: url('./fonts/ClashDisplay/ClashDisplay-Medium.otf') format('opentype');
    font-weight: 500;
}

@font-face {
    font-family: 'ClashDisplay';
    src: url('./fonts/ClashDisplay/ClashDisplay-Regular.otf') format('opentype');
    font-weight: 400;
}

@font-face {
    font-family: 'ClashDisplay';
    src: url('./fonts/ClashDisplay/ClashDisplay-Semibold.otf') format('opentype');
    font-weight: 600;
}