.loader {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    right: 0;
    color: var(--white);
    z-index: 50;
    background-size: cover;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__top {
        margin-top: 50px;
        ;

        &__logo {
            display: flex;
            gap: 20px;
            align-items: center;
            justify-content: center;

            &__img {
                width: 50px;
                height: 50px;
            }

            &__text {
                font-size: 31px;
                text-transform: uppercase;
                letter-spacing: 1.41px;
            }
        }
    }

    &__main {

        &__progress {
            background-color: var(--grey);
            width: 80%;
            margin: auto;
            height: 2px;
            position: relative;

            &__active {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                background-color: var(--white);
                transition: all 1s ease-in;

                &__logo {
                    position: absolute;
                    top: -18px;
                    right: -20px;
                    width: 40px;
                    height: 40px;
                    rotate: 45deg;
                }
            }
        }

        &__info {
            width: fit-content;
            margin: auto;
            margin-top: 50px;
            text-transform: uppercase;
        }
    }

    &__bottom {
        width: 100%;
        height: 200px;
        position: relative;

        &__shape {
            position: absolute;
            left: 50%;
            top: 0;
            transform: translateX(-50%);
            
            @media (min-width: 751px) {
                top: -100px;
            }
        }
    }
}