.button {
    // background-color: var(--black);
    background-color: transparent;
    border: 1px solid var(--white);
    border-radius: 11px;

    transition: all ease-in-out 0.2s;

    cursor: pointer;

    width: 140px;
    height: 40px;

    font-size: small;
    font-weight: bold;

    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background-color: var(--white);
        color: var(--black);
    }

    &__white {
        border: 1px solid transparent;
        background-color: var(--white);
        color: var(--black);

        &:hover {
            background-color: var(--black);
            color: var(--white);
            border-color: var(--white);
        }
    }

    &__link {
        color: var(--white);
        text-decoration: none;
    }
}