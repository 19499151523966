@import '_typography.scss';
@import '_breakpoints.scss';

:root {
    --white: #FFFFFF;
    --black: #000000;
    --blue: #0DF6FF;
    --grey: #808080;

    --mobile-screen: 921px
}

body {
    margin: 0;
    font-family: 'Satoshi', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

canvas {
    width: 100%;
    height: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

* {
    margin: 0;
    color: var(--white);
}

p {
    letter-spacing: 0.98px;
    font-size: 1em;
    font-weight: 500;

    @media (max-width: 921px) {
        font-size: 0.9em;
    }
}

h1,
h2 {
    font-family: ClashDisplay;
    font-weight: 400;
}

h1 {

    @media (max-width: 750px) {
        font-size: 35px;
    }


    @media (min-width: 751px) and (max-width: 1000px) {
        font-size: 70px;
    }

    @media (min-width: 1001px) and (max-width: 1500px) {
        font-size: 100px;
    }

    @media (min-width: 1501px) {
        font-size: 149px;
    }
}

h2 {
    font-size: 76px;
    letter-spacing: 1.91px;
    font-weight: 400;

    @media (max-width: 921px) {
        font-size: 30px;
    }
}
