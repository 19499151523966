.footer {
    color: var(--black);

    // Make the footer move up to the previous section
    margin-top: -100px;

    a {
        color: var(--white);
    }

    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        @media (min-width: 751px) {
            padding: 30px 80px;
        }

        @media (max-width: 750px) {
            flex-direction: column;
        }
    }

    &__left {
        width: 20%;

        @media (max-width: 750px) {
            display: none;
        }

        p {
            font-size: 14px;
            color: var(--white);
            opacity: 0.5;
        }
    }

    &__center {
        
        width: 60%;

        @media (max-width: 751px) {
            width: 80%;
        }

        text-align: center;
        p {
            font-size:  14px;
            color: var(--white);
        }
    }

    &__right {
        width: 20%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 30px;

        @media (max-width: 750px) {
            display: none;
        }

        &__img {
            width: 18px;
            height: 18px;
        }

        p {
            display: flex;
            gap: 10px;
            font-size: 14px;
            color: var(--white);
            opacity: 0.5;
        }
    }

    &__bottom {
        padding: 20px 80px;
        font-size: 14px;
        
        p {
            color: var(--white);
            font-weight: 900;
        }

        @media (min-width: 751px) {
            display: none;
        }
    }
}